// extracted by mini-css-extract-plugin
export var active = "Faq-module--active--G1j2v";
export var answer = "Faq-module--answer--RRhV8";
export var expand = "Faq-module--expand--QYsVb";
export var faqContainer = "Faq-module--faq-container--7WVOw";
export var faqItem = "Faq-module--faq-item--olnG8";
export var faqList = "Faq-module--faq-list--i-LO9";
export var gatsbyFocusWrapper = "Faq-module--gatsby-focus-wrapper--0uW64";
export var hideOnDesktop = "Faq-module--hide-on-desktop--92GFF";
export var hideOnMobile = "Faq-module--hide-on-mobile--SyToo";
export var iconCar = "Faq-module--icon-car--dq+k-";
export var iconCars = "Faq-module--icon-cars--RKMlC";
export var iconClock = "Faq-module--icon-clock--BIii2";
export var iconEletricCar = "Faq-module--icon-eletric-car--cvnxt";
export var iconFacebook = "Faq-module--icon-facebook--BVi0w";
export var iconGear = "Faq-module--icon-gear--Un-jN";
export var iconInstagram = "Faq-module--icon-instagram--jWwOe";
export var iconKm = "Faq-module--icon-km--71T61";
export var iconPhone = "Faq-module--icon-phone--KWGH6";
export var iconRight = "Faq-module--icon-right--12k1I";
export var iconWhatsapp = "Faq-module--icon-whatsapp--nIyZ7";
export var pageWidth = "Faq-module--page-width--sFTiN";
export var path1 = "Faq-module--path1--FSiT2";
export var path2 = "Faq-module--path2--3yedd";
export var path3 = "Faq-module--path3--rmhKh";
export var question = "Faq-module--question--quD1n";
export var view = "Faq-module--view--V1Jd5";
export var views = "Faq-module--views--idCQT";