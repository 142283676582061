import React from 'react'
import { useMediaQuery } from 'react-responsive'
import Slider from '@fil1pe/react-slider'

import electrical from '~/assets/img/svg/electric-car.svg'

import * as st from '~/assets/styl/CarCarousel.module.styl'
import { AnchorLink } from 'gatsby-plugin-anchor-links'
import { LazyImage } from 'react-lazy-images'

interface Props {
  vehicles: Array<{
    id: number
    title: string
    subtitle: string
    electric: boolean
    bodywork: string
    description: string[]
    price: string
    observation: string
    image: string
  }>
}

export default function CarCarousel({ vehicles }: Props) {
  const isDesktop = useMediaQuery({ query: '(min-width: 1301px)' })
  const isPhone = useMediaQuery({ query: '(min-width: 480px)' })

  function cardNumber() {
    if (isDesktop) {
      return 3
    } else if (isPhone) {
      return 2
    } else {
      return 1
    }
  }

  return (
    <section className={st.carsCarouselContainer}>
      <h2>Escolha o modelo ideal para a sua vida</h2>
      <Slider
        slidesToShow={cardNumber()}
        slidesToScroll={1}
        finite={isDesktop ? false : true}
        className={st.carsCarousel}
        renderArrow={(props) => <button {...props}></button>}
      >
        {vehicles.map((car) => (
          <>
            <LazyImage
              src={car.image}
              className={st.carImage}
              placeholder={({ ref }) => <img ref={ref} src={car.image} />}
              actual={({ imageProps }) => <img {...imageProps} />}
            />
            <div className={st.carCard} key={car.id}>
              <section className={st.carName}>
                <h3>{car.title}</h3>
                {car.electric && (
                  <LazyImage
                    src={electrical}
                    placeholder={({ ref }) => (
                      <img
                        ref={ref}
                        src={electrical}
                        alt="Simbolo para carro elétrico!"
                      />
                    )}
                    actual={({ imageProps }) => <img {...imageProps} />}
                  />
                )}
              </section>
              <span className={st.carDescription}>{car.subtitle}</span>
              <ul className={st.carHighlights}>
                {car.description.map((desc, index) => (
                  <li key={index}>{desc}</li>
                ))}
              </ul>
              <AnchorLink to="/assinatura-de-veiculos/">
                A partir de: <b>{car.price}</b>
              </AnchorLink>
              <small>{car.observation}</small>
            </div>
          </>
        ))}
      </Slider>
    </section>
  )
}
