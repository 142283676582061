import React from 'react'
import { useMediaQuery } from 'react-responsive'
import cn from 'classnames'

import * as st from '~/assets/styl/Showcase.module.styl'

import Slider from '@fil1pe/react-slider'

import { Link } from 'gatsby'
import { LazyImage } from 'react-lazy-images'

interface Props {
  banners: Array<{
    id: number
    title: string
    url: string
    external: boolean
    image: string
    mobile: string
  }>
}

export default function Showcase({ banners }: Props) {
  const isDesktop = useMediaQuery({ query: '(min-width: 768px)' })
  return (
    <div className={cn(st.showcaseContainer, 'page-width')}>
      <Slider renderArrow={(props) => <button {...props}></button>}>
        {(isDesktop &&
          banners.map((banner) => (
            <Link
              target={banner.external && '_blank'}
              rel={banner.external && 'noreferrer external nofollow'}
              to={banner.url}
              key={banner.id}
            >
              <LazyImage
                src={banner.image}
                placeholder={({ ref }) => (
                  <img ref={ref} alt={banner.title} src={banner.image} />
                )}
                actual={({ imageProps }) => <img {...imageProps} />}
              />
            </Link>
          ))) ||
          banners.map((banner) => (
            <Link
              target={banner.external && '_blank'}
              rel={banner.external && 'noreferrer external nofollow'}
              to={banner.url}
              key={banner.id}
            >
              <LazyImage
                src={banner.mobile}
                placeholder={({ ref }) => (
                  <img ref={ref} alt={banner.title} src={banner.mobile} />
                )}
                actual={({ imageProps }) => <img {...imageProps} />}
              />
            </Link>
          ))}
      </Slider>
    </div>
  )
}
