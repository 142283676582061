import React from 'react'
import { useMediaQuery } from 'react-responsive'

import { howWorksCards } from '~/data/'
import comoFunciona from '~/assets/img/como-funciona.jpg'

import * as st from '~/assets/styl/HowWorks.module.styl'
import { AnchorLink } from 'gatsby-plugin-anchor-links'
import { LazyImage } from 'react-lazy-images'

export default function HowWorks() {
  const isDesktop = useMediaQuery({ query: '(min-width: 768px)' })

  return (
    <div className={st.howWorksContainer} id="como-funciona">
      <section>
        <h2>Carro por assinatura é na Dimas Sempre</h2>
        <span className={st.subtitle}>
          <span>
            <b>Fácil de assinar, incrível de usar.</b>
          </span>
          Descubra como ter seu veículo 0Km por assinatura!
        </span>
        {!isDesktop && (
          <LazyImage
            className={st.imageMobile}
            src={comoFunciona}
            placeholder={({ ref }) => (
              <img
                ref={ref}
                src={comoFunciona}
                alt="Imagem de um homem de terno saindo do carro"
              />
            )}
            actual={({ imageProps }) => <img {...imageProps} />}
          />
        )}
        <div className={st.cardsWrapper}>
          <ul className={st.cards}>
            {howWorksCards.map(({ id, description, icon }) => (
              <li key={id}>
                <LazyImage
                  src={icon}
                  placeholder={({ ref }) => (
                    <img ref={ref} alt={description} src={icon} />
                  )}
                  actual={({ imageProps }) => <img {...imageProps} />}
                />
                <span>{id}</span>
                <p>{description}</p>
              </li>
            ))}
          </ul>
        </div>
        <AnchorLink to="/assinatura-de-veiculos/">Adquira seu carro</AnchorLink>
      </section>
      {isDesktop && (
        <div className={st.imageWrapper}>
          <LazyImage
            src={comoFunciona}
            placeholder={({ ref }) => (
              <img
                ref={ref}
                src={comoFunciona}
                alt="Imagem de um homem de terno saindo do carro"
              />
            )}
            actual={({ imageProps }) => <img {...imageProps} />}
          />
        </div>
      )}
    </div>
  )
}
