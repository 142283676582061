// extracted by mini-css-extract-plugin
export var emailInput = "Newsletter-module--email-input--m6Jz2";
export var gatsbyFocusWrapper = "Newsletter-module--gatsby-focus-wrapper--SZO0Y";
export var hideOnDesktop = "Newsletter-module--hide-on-desktop--eD+VJ";
export var hideOnMobile = "Newsletter-module--hide-on-mobile--GAax5";
export var iconCar = "Newsletter-module--icon-car--lVkh2";
export var iconCars = "Newsletter-module--icon-cars--gbjYv";
export var iconClock = "Newsletter-module--icon-clock--fq+Xl";
export var iconEletricCar = "Newsletter-module--icon-eletric-car--4HuGL";
export var iconFacebook = "Newsletter-module--icon-facebook--kd187";
export var iconGear = "Newsletter-module--icon-gear--dkK20";
export var iconInstagram = "Newsletter-module--icon-instagram--jbRpg";
export var iconKm = "Newsletter-module--icon-km--w11Wv";
export var iconPhone = "Newsletter-module--icon-phone--C2Xey";
export var iconRight = "Newsletter-module--icon-right--a2n8v";
export var iconWhatsapp = "Newsletter-module--icon-whatsapp--jl5pt";
export var inputWrapper = "Newsletter-module--input-wrapper--DPnQE";
export var newsletterContainer = "Newsletter-module--newsletter-container--FvBfn";
export var pageWidth = "Newsletter-module--page-width--9ghgf";
export var path1 = "Newsletter-module--path1--37K90";
export var path2 = "Newsletter-module--path2--4QGHp";
export var path3 = "Newsletter-module--path3--z5mN3";
export var privacy = "Newsletter-module--privacy--l2w1s";
export var view = "Newsletter-module--view--6fvxC";
export var views = "Newsletter-module--views--HRm13";