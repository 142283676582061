// extracted by mini-css-extract-plugin
export var gatsbyFocusWrapper = "Outsourcing-module--gatsby-focus-wrapper--EsNWL";
export var hideOnDesktop = "Outsourcing-module--hide-on-desktop--A6Xy9";
export var hideOnMobile = "Outsourcing-module--hide-on-mobile--n2q0a";
export var iconCar = "Outsourcing-module--icon-car--u8R33";
export var iconCars = "Outsourcing-module--icon-cars--1h5KB";
export var iconClock = "Outsourcing-module--icon-clock--cWbOK";
export var iconEletricCar = "Outsourcing-module--icon-eletric-car--Ks02-";
export var iconFacebook = "Outsourcing-module--icon-facebook--L9Pgt";
export var iconGear = "Outsourcing-module--icon-gear--TlahL";
export var iconInstagram = "Outsourcing-module--icon-instagram--G+M8n";
export var iconKm = "Outsourcing-module--icon-km--nO4H6";
export var iconPhone = "Outsourcing-module--icon-phone--FFb09";
export var iconRight = "Outsourcing-module--icon-right--XzvZU";
export var iconWhatsapp = "Outsourcing-module--icon-whatsapp--qK9-o";
export var imageWrapper = "Outsourcing-module--image-wrapper--YpsUM";
export var outsourcingContainer = "Outsourcing-module--outsourcing-container--icBhV";
export var pageWidth = "Outsourcing-module--page-width--WsE3K";
export var path1 = "Outsourcing-module--path1--GaEzO";
export var path2 = "Outsourcing-module--path2--SE85Y";
export var path3 = "Outsourcing-module--path3--yd4xI";
export var view = "Outsourcing-module--view--nYXet";
export var views = "Outsourcing-module--views--MZiFr";