// extracted by mini-css-extract-plugin
export var gatsbyFocusWrapper = "Plans-module--gatsby-focus-wrapper--LeP7L";
export var hideOnDesktop = "Plans-module--hide-on-desktop--Y3GaZ";
export var hideOnMobile = "Plans-module--hide-on-mobile--J7hSl";
export var iconCar = "Plans-module--icon-car--2syL0";
export var iconCars = "Plans-module--icon-cars--ev3x2";
export var iconClock = "Plans-module--icon-clock--oGGIm";
export var iconEletricCar = "Plans-module--icon-eletric-car--4xhJS";
export var iconFacebook = "Plans-module--icon-facebook--H957b";
export var iconGear = "Plans-module--icon-gear--6yRa6";
export var iconInstagram = "Plans-module--icon-instagram--lmJal";
export var iconKm = "Plans-module--icon-km--jhRXQ";
export var iconPhone = "Plans-module--icon-phone--FlToE";
export var iconRight = "Plans-module--icon-right--d3fwY";
export var iconWhatsapp = "Plans-module--icon-whatsapp--kwlvY";
export var pageWidth = "Plans-module--page-width--Dxh+E";
export var path1 = "Plans-module--path1--tFzkO";
export var path2 = "Plans-module--path2--wgTC+";
export var path3 = "Plans-module--path3--T4nkK";
export var plansAndFranchise = "Plans-module--plans-and-franchise--kj0p6";
export var plansContainer = "Plans-module--plans-container--pY-jP";
export var plansWrapper = "Plans-module--plans-wrapper--ddLbX";
export var view = "Plans-module--view---rEJH";
export var views = "Plans-module--views--Wp6Qv";