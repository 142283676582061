import React from 'react'
import { Head } from '~/components'
import { PageProps } from 'gatsby'
import Menu from '~/components/Menu'
import Banner from '~/components/Banner'
import bannerHome from '~/assets/img/banner-home.jpg'
import HowWorks from '~/components/HowWorks'
import Plans from '~/components/Plans'
import Benefits from '~/components/Benefits'
import Showcase from '~/components/Showcase'
import CarCarousel from '~/components/CarCarousel'
import Faq from '~/components/Faq'
import Outsourcing from '~/components/Outsourcing'
import Newsletter from '~/components/Newsletter'
import Footer from '~/components/Footer'

import { useApi } from '~/siteApi'
import useScroll from '~/components/useScroll'

interface Context {
  deadlines: Array<never>
  franchises: Array<never>
  faqs: Array<never>
  banners: Array<never>
  benefits: Array<never>
  vehicles: Array<never>
}

const Home = ({ pageContext }: PageProps) => {
  const { deadlines, franchises, faqs, banners, benefits, vehicles }: Context =
    useApi(pageContext, 'page-home')
  useScroll()

  return (
    <>
      <Head />
      <Banner
        image={bannerHome}
        title="Você sempre de carro novo!"
        buttonText="Saiba como funciona"
        anchor="#como-funciona"
      />
      <Showcase banners={banners} />
      <HowWorks />
      <Plans deadlines={deadlines} franchises={franchises} />
      <Benefits benefits={benefits} />
      <CarCarousel vehicles={vehicles} />
      <Faq faqs={faqs} />
      <Outsourcing />
      <Newsletter />
      <Footer />
    </>
  )
}

export default Home
