// extracted by mini-css-extract-plugin
export var cards = "HowWorks-module--cards--on904";
export var cardsWrapper = "HowWorks-module--cards-wrapper--Cj66F";
export var gatsbyFocusWrapper = "HowWorks-module--gatsby-focus-wrapper--DpluN";
export var hideOnDesktop = "HowWorks-module--hide-on-desktop--HDkvk";
export var hideOnMobile = "HowWorks-module--hide-on-mobile--xEuq1";
export var howWorksContainer = "HowWorks-module--how-works-container--QQvKw";
export var iconCar = "HowWorks-module--icon-car--Doxou";
export var iconCars = "HowWorks-module--icon-cars--4uUWd";
export var iconClock = "HowWorks-module--icon-clock--RZjEU";
export var iconEletricCar = "HowWorks-module--icon-eletric-car--jnJv4";
export var iconFacebook = "HowWorks-module--icon-facebook--vlKAo";
export var iconGear = "HowWorks-module--icon-gear--39cUr";
export var iconInstagram = "HowWorks-module--icon-instagram--oOdwh";
export var iconKm = "HowWorks-module--icon-km--rMeD3";
export var iconPhone = "HowWorks-module--icon-phone--qUXBN";
export var iconRight = "HowWorks-module--icon-right--TnNdm";
export var iconWhatsapp = "HowWorks-module--icon-whatsapp--2id1c";
export var imageMobile = "HowWorks-module--image-mobile--Sc5Sb";
export var imageWrapper = "HowWorks-module--image-wrapper--tc784";
export var pageWidth = "HowWorks-module--page-width--o8T3b";
export var path1 = "HowWorks-module--path1--+AVAp";
export var path2 = "HowWorks-module--path2--97g2N";
export var path3 = "HowWorks-module--path3--his9T";
export var subtitle = "HowWorks-module--subtitle--4aWt4";
export var view = "HowWorks-module--view--fwRMH";
export var views = "HowWorks-module--views--6qpW0";