// extracted by mini-css-extract-plugin
export var carCard = "CarCarousel-module--car-card--9P5l1";
export var carDescription = "CarCarousel-module--car-description--Ma6bh";
export var carHighlights = "CarCarousel-module--car-highlights--n6x21";
export var carImage = "CarCarousel-module--car-image--17w48";
export var carName = "CarCarousel-module--car-name--2nI3e";
export var carsCarousel = "CarCarousel-module--cars-carousel--QZH-n";
export var carsCarouselContainer = "CarCarousel-module--cars-carousel-container--YHEF6";
export var gatsbyFocusWrapper = "CarCarousel-module--gatsby-focus-wrapper--vUNUJ";
export var hideOnDesktop = "CarCarousel-module--hide-on-desktop--4iJe5";
export var hideOnMobile = "CarCarousel-module--hide-on-mobile--fKkaW";
export var iconCar = "CarCarousel-module--icon-car--p2Nm5";
export var iconCars = "CarCarousel-module--icon-cars--w2pku";
export var iconClock = "CarCarousel-module--icon-clock--TmHl2";
export var iconEletricCar = "CarCarousel-module--icon-eletric-car--d9I8+";
export var iconFacebook = "CarCarousel-module--icon-facebook--cHVKo";
export var iconGear = "CarCarousel-module--icon-gear--YF8Nj";
export var iconInstagram = "CarCarousel-module--icon-instagram--9gseg";
export var iconKm = "CarCarousel-module--icon-km--nocek";
export var iconPhone = "CarCarousel-module--icon-phone--17ant";
export var iconRight = "CarCarousel-module--icon-right--5L1rB";
export var iconWhatsapp = "CarCarousel-module--icon-whatsapp--E6nms";
export var pageWidth = "CarCarousel-module--page-width--MLaSA";
export var path1 = "CarCarousel-module--path1--dDKJp";
export var path2 = "CarCarousel-module--path2--B3a46";
export var path3 = "CarCarousel-module--path3--km91M";
export var view = "CarCarousel-module--view--Q+e8G";
export var views = "CarCarousel-module--views--euE28";