import React from 'react'
import { AnchorLink } from 'gatsby-plugin-anchor-links'

import * as st from '~/assets/styl/Outsourcing.module.styl'

import terceirizacao from '~/assets/img/terceirizacao-home.jpg'
import { LazyImage } from 'react-lazy-images'

export default function Outsourcing() {
  return (
    <div className={st.outsourcingContainer}>
      <div className={st.imageWrapper}>
        <LazyImage
          src={terceirizacao}
          placeholder={({ ref }) => <img ref={ref} src={terceirizacao} />}
          actual={({ imageProps }) => <img {...imageProps} />}
        />
      </div>
      <section>
        <h2>Procurando veículos para a sua empresa?</h2>
        <span>
          Conte com mais facilidade e menos custos para dirigir seu negócio.
        </span>
        <AnchorLink to="/terceirizacao-de-frota/">
          Saiba mais sobre terceirização de frota
        </AnchorLink>
      </section>
    </div>
  )
}
