import React, { useState } from 'react'

import cn from 'classnames'

import * as st from '~/assets/styl/Benefits.module.styl'
import { LazyImage } from 'react-lazy-images'

interface Props {
  benefits: Array<{
    id: number | string
    title: string
    description: string
    image: string
  }>
}

export default function Benefits({ benefits }: Props) {
  const [activeTab, setActiveTab] = useState(1)

  return (
    <section id="beneficios" className={cn(st.benefitsContainer, 'page-width')}>
      <h2>Nossos benefícios</h2>
      <p className={st.benefitsHeader}>
        <b>Tudo que você precisa está na Dimas Sempre!</b>
        Conheça nossos planos de assinatura e saia de carro 0Km.
      </p>
      <div className={st.benefitsTabs}>
        <ul className={st.benefitsList}>
          {benefits.map(({ id, title }) => (
            <li
              key={id}
              id={id}
              value={id}
              className={cn(st.benefitsItem, {
                [st.active]: activeTab === id,
              })}
              onClick={() => {
                setActiveTab(id)
              }}
            >
              {title}
            </li>
          ))}
        </ul>
        <div className={st.benefitCard}>
          {benefits.map(({ id, title, description, image }) => (
            <div
              key={id}
              id={id}
              className={cn(st.tabContent, {
                [st.activeTab]: activeTab === id,
              })}
            >
              <LazyImage
                src={image}
                key={id}
                placeholder={({ ref }) => (
                  <img ref={ref} key={id} src={image} />
                )}
                actual={({ imageProps }) => <img {...imageProps} />}
              />
              <section className={st.cardContent}>
                <h3>{title}</h3>
                <div
                  dangerouslySetInnerHTML={{
                    __html: description.replace(/\n/g, '<br/>'),
                  }}
                ></div>
              </section>
            </div>
          ))}
        </div>
      </div>
    </section>
  )
}
