import { useLayoutEffect } from 'react'
import { scroller } from 'react-scroll'

export default function useScroll() {
  useLayoutEffect(() => {
    if (window.location.hash) {
      const f = setInterval(() => {
        const scrollY = (
          document.querySelector(window.location.hash) as HTMLElement
        )?.offsetTop
        const viewsCount = (document.querySelector('.views') as HTMLElement)
          .childElementCount
        if (viewsCount === 1 && document.body.offsetHeight >= scrollY) {
          scroller.scrollTo(window.location.hash.split('#')[1], {
            smooth: true,
            duration: 250,
          })
          clearInterval(f)
        }
      }, 50)
    }
  }, [])
}
