// extracted by mini-css-extract-plugin
export var gatsbyFocusWrapper = "Showcase-module--gatsby-focus-wrapper--C9Vij";
export var hideOnDesktop = "Showcase-module--hide-on-desktop--Iz3JJ";
export var hideOnMobile = "Showcase-module--hide-on-mobile--jEugg";
export var iconCar = "Showcase-module--icon-car--Kal-d";
export var iconCars = "Showcase-module--icon-cars--dUcrd";
export var iconClock = "Showcase-module--icon-clock--hbif6";
export var iconEletricCar = "Showcase-module--icon-eletric-car--GyMHh";
export var iconFacebook = "Showcase-module--icon-facebook--IO5sN";
export var iconGear = "Showcase-module--icon-gear--PLPbh";
export var iconInstagram = "Showcase-module--icon-instagram---rmDV";
export var iconKm = "Showcase-module--icon-km--ildVF";
export var iconPhone = "Showcase-module--icon-phone--V5IQP";
export var iconRight = "Showcase-module--icon-right--BMNP1";
export var iconWhatsapp = "Showcase-module--icon-whatsapp--0l614";
export var pageWidth = "Showcase-module--page-width--WXQEc";
export var path1 = "Showcase-module--path1--ddFGj";
export var path2 = "Showcase-module--path2--BPuT8";
export var path3 = "Showcase-module--path3--iEBoW";
export var showcaseContainer = "Showcase-module--showcase-container--jtxwa";
export var view = "Showcase-module--view--YVhOw";
export var views = "Showcase-module--views--6Bui1";