import React, { useState } from 'react'

import cn from 'classnames'

import * as st from '~/assets/styl/Faq.module.styl'

interface Props {
  faqs: Array<{
    id: number
    title: string
    description: string
  }>
}

export default function Faq({ faqs }: Props) {
  const [activeAccordion, setActiveAccordion] = useState()
  const [expandAccordion, setExpandAccordion] = useState(false)

  function handleExpandAccordion() {
    if (!expandAccordion) {
      setExpandAccordion(!expandAccordion)
    } else {
      return
    }
  }

  function handleActiveAccordion(id) {
    if (activeAccordion === id) {
      setActiveAccordion(null)
    } else {
      setActiveAccordion(id)
    }
  }

  return (
    <section id="duvidas" className={cn(st.faqContainer, 'page-width')}>
      <h2>Perguntas frenquentes</h2>
      <p>Selecionamos as principais dúvidas referentes aos nossos serviços.</p>
      <ul
        className={cn(st.faqList, { [st.active]: expandAccordion })}
        onClick={handleExpandAccordion}
      >
        {faqs.map((item, index) => (
          <li
            key={index}
            className={cn(st.faqItem, {
              [st.active]: activeAccordion === item.id,
            })}
          >
            <div
              className={cn(st.question, {
                [st.active]: activeAccordion === item.id,
              })}
              onClick={() => handleActiveAccordion(item.id)}
            >
              {item.title}
            </div>
            <span
              className={cn(st.answer, {
                [st.active]: activeAccordion === item.id,
              })}
              dangerouslySetInnerHTML={{
                __html: item.description.replace(/\n/g, '<br/>'),
              }}
            ></span>
          </li>
        ))}
      </ul>
      <button
        className={cn(st.expand, { [st.active]: expandAccordion })}
        onClick={handleExpandAccordion}
      >
        Expandir perguntas frequentes
      </button>
    </section>
  )
}
