import React from 'react'
import cn from 'classnames'

import * as st from '~/assets/styl/Plans.module.styl'
import { AnchorLink } from 'gatsby-plugin-anchor-links'

interface Props {
  deadlines: Array<{
    id: number
    title: string
  }>
  franchises: Array<{
    id: number
    title: string
  }>
}

export default function Plans({ deadlines, franchises }: Props) {
  return (
    <section id="planos" className={cn(st.plansContainer, 'page-width')}>
      <h2>Planos de assinatura Dimas Sempre</h2>
      <div className={st.plansWrapper}>
        <div className={st.plansAndFranchise}>
          <span>Prazos</span>
          <p>O tempo que você pretende ficar com o carro:</p>
          <ul>
            {deadlines.map((deadline, index) => (
              <li key={index}>{deadline.title}</li>
            ))}
          </ul>
        </div>
        <div className={st.plansAndFranchise}>
          <span>Franquias</span>
          <p>O quanto você irá rodar com o carro mensalmente:</p>
          <ul>
            {franchises.map((franchise, index) => (
              <li key={index}>{franchise.title}</li>
            ))}
          </ul>
        </div>
      </div>
      <AnchorLink to="/assinatura-de-veiculos/">Adquira seu carro</AnchorLink>
    </section>
  )
}
