import React, { useState } from 'react'

import cn from 'classnames'
import { navigate } from 'gatsby'
import { useCookies } from 'react-cookie'

import requestData from '~/siteApi/form.js'

import * as st from '~/assets/styl/Newsletter.module.styl'
import { AnchorLink } from 'gatsby-plugin-anchor-links'

export default function Newsletter() {
  const [email, setEmail] = useState('')
  const [termo, setTermo] = useState(false)
  const [privacy, setPrivacy] = useState(false)
  const [communications, setCommunications] = useState(false)
  const [cookies] = useCookies()
  const terms_of_use = termo
  const privacy_policy = privacy

  function handleChange(e) {
    setEmail(e.target.value)
  }

  function handleTermo(e) {
    setTermo(e.target.checked)
    setPrivacy(e.target.checked)
  }

  function handleComunicacao(e) {
    setCommunications(e.target.checked)
  }

  function handleSubmit(e) {
    e.preventDefault()
    requestData('post', 'conversions', {
      email,
      legal_bases: [
        {
          category: 'communications',
          type: 'consent',
          status: 'granted',
        },
        {
          category: 'data_processing',
          type: 'consent',
          status: 'granted',
        },
      ],
      token_rdstation: '36ef8b1445846a96de8abe06eaf566be',
      identificador: 'Site - Receba novidades',
      traffic_source: cookies?.['__trf.src'] || cookies?.__utmzz,
      __utmz: cookies?.['__trf.src'] || cookies?.__utmzz,
    }).then((data) => {
      if (data.result === 'success') navigate('/obrigado')
      else alert(data.msg)
    })
  }

  return (
    <section className={cn(st.newsletterContainer, 'page-width')}>
      <h2>Cadastre-se e fique sempre por dentro das novidades Dimas Sempre.</h2>
      <form onSubmit={handleSubmit}>
        <div className={st.inputWrapper}>
          <div className={st.emailInput}>
            <label>
              <input
                type="email"
                id="email"
                placeholder="Qual seu e-mail?"
                value={email}
                required
                onChange={handleChange}
              />
            </label>
          </div>
          <div className={st.privacy}>
            <label>
              <input
                id="use_terms"
                type="checkbox"
                name="termo"
                onChange={handleTermo}
                required
              />
              <small>
                Eu concordo em receber comunicações. Ao informar meus dados, eu
                concordo com a
                <AnchorLink to="/politica-de-privacidade/">
                  Política de privacidade
                </AnchorLink>
                e<AnchorLink to="/termos-de-uso/">Termos de uso</AnchorLink>
              </small>
            </label>
            <label>
              <input
                id="use_terms"
                type="checkbox"
                name="comunicacao"
                onChange={handleComunicacao}
                required
              />
              <small>
                Autorizo o Grupo Dimas a armazenar meus dados pessoais para
                enviar campanhas de marketing e informações sobre a empresa nos
                canais: Telefone, Email e SMS.
              </small>
            </label>
          </div>
        </div>
        <button type="submit">Enviar</button>
      </form>
    </section>
  )
}
