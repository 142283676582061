// extracted by mini-css-extract-plugin
export var active = "Benefits-module--active--G1uww";
export var activeTab = "Benefits-module--active-tab--+L-Bg";
export var benefitCard = "Benefits-module--benefit-card--doqOC";
export var benefitsContainer = "Benefits-module--benefits-container--Qe0nG";
export var benefitsHeader = "Benefits-module--benefits-header--hKMPk";
export var benefitsItem = "Benefits-module--benefits-item--sSyw2";
export var benefitsList = "Benefits-module--benefits-list--keWcQ";
export var benefitsTabs = "Benefits-module--benefits-tabs--P+EP1";
export var cardContent = "Benefits-module--card-content--YJO+1";
export var gatsbyFocusWrapper = "Benefits-module--gatsby-focus-wrapper--Cs8on";
export var hideOnDesktop = "Benefits-module--hide-on-desktop--MX5ng";
export var hideOnMobile = "Benefits-module--hide-on-mobile--3iNFv";
export var iconCar = "Benefits-module--icon-car--ilS8V";
export var iconCars = "Benefits-module--icon-cars--oc2HH";
export var iconClock = "Benefits-module--icon-clock--2IegV";
export var iconEletricCar = "Benefits-module--icon-eletric-car--Ze8eX";
export var iconFacebook = "Benefits-module--icon-facebook--7KtDq";
export var iconGear = "Benefits-module--icon-gear--S2RdL";
export var iconInstagram = "Benefits-module--icon-instagram--gDbtL";
export var iconKm = "Benefits-module--icon-km--YZVoW";
export var iconPhone = "Benefits-module--icon-phone--hjEfJ";
export var iconRight = "Benefits-module--icon-right--sRtVg";
export var iconWhatsapp = "Benefits-module--icon-whatsapp--b6eRh";
export var pageWidth = "Benefits-module--page-width--Ykyfi";
export var path1 = "Benefits-module--path1--EUJbR";
export var path2 = "Benefits-module--path2--9i6C8";
export var path3 = "Benefits-module--path3--R7WzW";
export var tabContent = "Benefits-module--tab-content--Mpaz6";
export var view = "Benefits-module--view--OG6Tu";
export var views = "Benefits-module--views--0SOwY";